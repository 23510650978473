<template>
  <div class="container">
    <div class="columns">
      <div class="column content section">
        <callout type="success">
          Your ACH request ID <b>{{ confirmationId }}</b> has been submitted. {{ department }} has been notified of your request.
          Once your information has been approved and entered into the system, you will receive a confirmation email.
          <div v-if="departmentExists">If you have questions, please reach out to your department contact.</div>
          <div
            v-if="!departmentExists"
            v-html="`If you have questions, please reach out to <a href='mailto:VoucherVerification@phila.gov'>voucherverification@phila.gov</a>.`"
          />
        </callout>
      </div>
    </div>
  </div>
</template>

<script>

import { Callout } from '@phila/phila-ui';

export default {
  name: "AchFormConfirmation",
  components: {
    Callout,
  },
  data() {
    return {
      test: true,
    };
  },
  computed: {
    confirmationId() {
      return this.$store.state.ach.submittedAchData.humanReadableId;
    },
    departmentExists() {
      let value = false;
      if (this.$store.state.ach.submittedAchData.department) {
        value = true;
      }
      return value;
    },
    department() {
      let value;
      if (this.$store.state.ach.submittedAchData.department) {
        value = this.$store.state.ach.submittedAchData.department;
      } else {
        value = 'Voucher verification';
      }
      return value;
    },
  },

};

</script>
